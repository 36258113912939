<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Contact Vendor</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-vendor">Vendor </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Contact Vendor
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>

        <div class="card info-detail">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <router-link to="/data-vendor/detail" class="nav-link"
                >Detail Vendor
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/data-vendor/data-contact"
                class="nav-link active"
                >Contact Vendor
              </router-link>
            </li>
          </ul>

          <div class="row mt-5">
            <div class="col-6">
              <div class="title">Contact Vendor</div>
              <div class="sub-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </div>
            </div>
            <div class="col-6 align-self-center text-right">
              <router-link to="/data-vendor/data-contact/tambah" class="btn-add"
                >+ Tambah
              </router-link>
            </div>
          </div>

          <hr />

          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Nama</th>
                  <th style="width: 20%">Jabatan</th>
                  <th style="width: 15%">Nomor Telepon</th>
                  <th style="width: 10%">Email</th>
                  <th style="width: 5%">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>Mulyadi</td>
                  <td>Pemilik</td>
                  <td>08625331288</td>
                  <td>jaya.abadi@gmail.com</td>
                  <td>
                    <div class="green-bedge">Aktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>Nigel Mansell</td>
                  <td>Akuntan</td>
                  <td>08625331288</td>
                  <td>nigel.man@gmail.com</td>
                  <td>
                    <div class="green-bedge">Aktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>Nigel Mansell</td>
                  <td>Akuntan</td>
                  <td>08625331288</td>
                  <td>nigel.man@gmail.com</td>
                  <td>
                    <div class="red-bedge">Nonaktif</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>Nigel Mansell</td>
                  <td>Akuntan</td>
                  <td>08625331288</td>
                  <td>nigel.man@gmail.com</td>
                  <td>
                    <div class="red-bedge">Nonaktif</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2
    // TableLite
  },

  data() {
    return {
      myValue: "aaaa",
      statusCompany: ["Aktif", "Tidak Aktif"], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: ["1 Bulan", "2 Bulan"],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
    };
  },

  mounted() {
    $("#tableDepartment").DataTable({
      info: false,
    });
    this.tooltip();
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail() {
      this.$router.push("/data-vendor/data-contact/detail");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },

    clickCancel() {
      $("input").attr("readonly");
      $(".groupBtn-detail").removeClass("d-none");
      $(".groupBtn-save-edit ").addClass("d-none");
      $("input").attr("readonly", true);
      $(".edit-vendor ").addClass("d-none");
      $(".input-none").removeClass("d-none");
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
</style>
